import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import './SecondStepJobListing.scss';
import { PayOptionConstant } from '../../../constants/JobConstant';
import { JobOfferingContext } from '../../../context/JobOfferringContext';
import FireTracking from '../../../assets/FireTracking/fireTracking';
import { TYPE_TRACKING } from '../../../constants/TrackingConstant';
import { useLocation } from 'react-router-dom';
import { JobApi } from '../../../api/jobApi';
import DefaultImage from '../../../assets/images/building.jpg';

const SecondStepJobListing = () => {
    const baseSerpUrl = process.env.REACT_APP_SERP_BASE_URL;

    const { jobs, setJobs } = useContext(JobOfferingContext);

    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const keyword = queryParams.get('keyword');
    const campaignId = queryParams.get('camp_id');
    const queryString = location.search;
    const [currentJobIndex, setCurrentJobIndex] = useState(0);
    const [loading, setLoading] = useState(false);
    const [skipCount, setSkipCount] = useState(0);
    const [tinyJobsArray, setTinyJobsArray] = useState([]);
    const tinyJobsALreadyAdded = useRef(false)
    const params = JSON.parse(localStorage.getItem('job-genius-user-info'));
    const currentJob = jobs[currentJobIndex];
    let userLocation = useRef("");

    const testObj = useMemo(() => {
        const weightKeyword = keyword ? 0.1 : 0;

        return {
            keyword,
            campaignId,
            testing: false,
            isFilterDistance: false,
            distance: 100,
            weightKeyword,
            weightBid: 0.3,
            weightCR: 0.2,
            weightGEO: 0.4,
            page: 0,
            size: 6 // Assuming up to 6 jobs
        }
    }, [keyword, campaignId]);

    const handleSkip = (currJob) => {
        const dataTracking = {
            jobId: currJob?.jobId,
            majorCategory: currJob?.majorCategory,
            conversion: 0
        };
        FireTracking(TYPE_TRACKING.applyJob, dataTracking);
        if (currentJobIndex >= 5 || currentJobIndex >= jobs.length - 1) {
            // Redirect to another page after the 6th job
            window.location.replace(`${baseSerpUrl}/job${queryString || '?'}&page=0&size=10&what=${keyword || ""}&where=${userLocation.current}`);
            return;
        }

        if (skipCount >= 2 && !(tinyJobsALreadyAdded.current)) {
            // Show FixedTinyJobArray after 4 skips, limited to 6 jobs total
            if (tinyJobsArray.length > 0) {
                const tinyJobs = tinyJobsArray.slice(0, 5 - currentJobIndex);
                const remainingJobs = jobs.slice(0, currentJobIndex + 1)
                setJobs([...remainingJobs, ...tinyJobs]);
            }
            setCurrentJobIndex(prevIndex => prevIndex + 1);
            setSkipCount(0);
        } else {
            setCurrentJobIndex(prevIndex => prevIndex + 1);
            setSkipCount(prev => prev + 1);
        }
    };

    const handleApply = (currJob) => {
        try {
            setSkipCount(0);
            // Implement logic for pixel fire
            const dataTracking = {
                jobId: currJob?.jobId,
                majorCategory: currJob?.majorCategory,
                conversion: 1
            }
            FireTracking(TYPE_TRACKING.applyJob, dataTracking);
        } catch (error) {
            console.log("Error while firing apply event", error)
        }

        setCurrentJobIndex(prevIndex => Math.min(prevIndex + 1, jobs.length - 1));
        const windowAddress = params
            ? `${currJob.shaUrl}${queryString ? `${queryString}&first=${params.firstName}&last=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}` : `?first=${params.firstName}&last=${params.lastName}&email=${params.email}&phone=${params.phoneNumber}`}`
            : `${currJob.shaUrl}${queryString || ''}`; window.open(windowAddress)
    };


    const fetchData = useCallback(async () => {
        setLoading(true);

        try {
            // Run both API calls in parallel
            const jobDataResponse = await JobApi.getJobApi({ ...testObj });
            const jobData = jobDataResponse?.data?.normalJobResult?.content || [];
            const tinyJobData = jobDataResponse?.data?.tinyJobResult?.content || [];
            userLocation.current = jobDataResponse?.data?.userCity || "";

            // If fetchedJobs are less than 6, concat with tinyJobData
            const combinedJobs = jobData.length < 6
                ? [...jobData, ...tinyJobData.slice(0, 6 - jobData.length)]
                : jobData;

            if (jobData.length < 6) {
                tinyJobsALreadyAdded.current = true;
            }

            setJobs(combinedJobs);
            setTinyJobsArray(tinyJobData)
        } catch (err) {
            console.error('Error fetching job data:', err);
        } finally {
            setLoading(false);
        }
    }, [testObj, setJobs]);


    useEffect(() => {
        fetchData();
    }, [testObj]);
    return (
        <div>
            {loading ? (
                <div className='loading-spinner'>
                    <p>Loading...</p>
                </div>
            ) : currentJob ? (
                <div className='job-browser'>
                    <div className='company-image'>
                        <img src={currentJob.companyLogo || DefaultImage} alt="companyLogo" loading='lazy' />
                    </div>
                    <div className='job-listing'>
                        <p className='job'>
                            {`${currentJob.title} in ${currentJob.postalCode || 'global'} up to $${currentJob.payTo || currentJob.payFrom || '0'}/${PayOptionConstant[currentJob.payOption] || "hr"}`}
                        </p>
                    </div>
                    <hr />
                    <div className='footer-job-button'>
                        <button className='view-job' onClick={() => handleApply(currentJob)}>Apply</button>
                        <button className='skip-job' onClick={() => handleSkip(currentJob)}>Skip</button>
                    </div>
                </div>
            ) : (
                <p>No more jobs to show</p>
            )}
        </div>
    );
};

export default SecondStepJobListing;
