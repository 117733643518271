import React from 'react';
import { PayOptionConstant } from '../../constants/JobConstant';

const ShortJob = ({jobDetail, handleJobClick, id}) => {
    return (
        <div className='gist-job' onClick={() => handleJobClick(id)}>
            <div className='image-job'>
                <img src={jobDetail.companyLogo} alt="job-image" />
            </div>
            <div className='job-details'>
                <p className='title-job'>{`${jobDetail.title} - Up to $${jobDetail.payTo || jobDetail.payFrom}/${PayOptionConstant[jobDetail.payOption]}`}</p>
                <p className='location-job'>
                    {`lululemon | ${jobDetail.postalCode}`}
                </p>
            </div>
        </div>
    );
};

export default ShortJob;