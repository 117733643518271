import React, { useState } from "react";

export const LanderPageContext = React.createContext();

const LanderPageContextProvider = ({ children }) => {
    const [formSteps, setFormSteps] = useState(1);
    const [userInfo, setUserInfo] = useState({});
    //Basic Information Page Steps
    const [step, setStep] = useState(1);


    return (
        <LanderPageContext.Provider
            value={{
                formSteps,
                setFormSteps,

                userInfo,
                setUserInfo,

                step,
                setStep
            }}
        >
            {children}
        </LanderPageContext.Provider>
    )
}

export default LanderPageContextProvider;