export const PayOptionConstant = {
    1: "PAY_IN_SALARY",
    2: "Monthly",
    3: "Weekly",
    4: "Bi_Weekly",
    5: "Daily",
    6: "Hourly",
    7: "Annually",
    8: "CPM",
};

export const CurrentcyConstant = {
    1: "USD",
    2: "CAD"
};

export const JobTypeConstant = {
    NORMAL_JOB: 0,
    TINY_JOB: 1
}