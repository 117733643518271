import React, { useState } from "react";

export const JobOfferingContext = React.createContext();

const JobOfferingModalProvider = ({ children }) => {

    const [jobOfferingStep, setJobOfferingStep] = useState(3);
    const [jobs, setJobs] = useState([]);

    return (
        <JobOfferingContext.Provider
            value={{
                jobOfferingStep,
                setJobOfferingStep,
                jobs,
                setJobs
            }}
        >
            {children}
        </JobOfferingContext.Provider>
    )
}

export default JobOfferingModalProvider;