import React from 'react';
import './ProgressBar.scss';

const ProgressBar = ({ value }) => {

    return (
        <div className='progress-container'>
            <div className='progress-bar'>
                <div className={value >= 1 ? "step completed" : "step"}>✔</div>
                <div className={value >= 2 ? "step completed" : "step"}>✔</div>
                <div className={value >= 3 ? "step completed" : "step"}>✔</div>
                <div className='step'>Job Listings</div>
            </div>
            <p className='para-sponser'>
                Take advantage of this opportunity!
            </p>
        </div>
    );
};

export default ProgressBar;