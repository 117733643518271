import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import Compose from './context/Compose';
import LanderPageContextProvider from './context/LanderPageContext';
import JobOfferingModalProvider from './context/JobOfferringContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <Suspense fallback={<span></span>}>
    <Compose
      components={[
        LanderPageContextProvider,
        JobOfferingModalProvider
      ]}
    >
      <App />
    </Compose>
  </Suspense>
);