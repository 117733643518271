import React from 'react';
import { PayOptionConstant } from '../../constants/JobConstant';

const DetailsJobs = ({jobDetail, handleJobClick, id}) => {
    return (
        <div className='detail-job' onClick={() =>handleJobClick(id)}>
            <div className='job-title-header'>
                <div className='logo'>
                    <img src={jobDetail.companyLogo} alt="job-image" />
                </div>
                <span className='job-title'>{`${jobDetail.title} - Hiring in ${jobDetail.postalCode} - $${jobDetail.payTo || jobDetail.payFrom}/${PayOptionConstant[jobDetail.payOption]}`}</span>
            </div>
            <div className='job-detail'>
                <p className='description'>
                    The Accountant Supervisor's responsobilities include recruiting
                    and training accountant staff
                </p>
            </div>
            <button className='apply-button'>Quick Apply</button>
        </div>
    );
};

export default DetailsJobs;