import React, { useContext, useEffect } from 'react';
import './JobOffering.scss'
import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { JobOfferingContext } from '../../context/JobOfferringContext';
import SecondStepJobListing from '../../components/JobListingProgress/SecondStepJobListing/SecondStepJobListing';

const JobOffering = () => {

    const { jobOfferingStep } = useContext(JobOfferingContext);

    // const renderJobOfferingComponent = () => {
    //     switch (jobOfferingStep) {
    //         case 1: {
    //             return <ResumeComponent />
    //         }

    //         case 3: {
    //             return <SecondStepJobListing />
    //         }
    //         default:
    //             return <SecondStepJobListing />
    //     }
    // }

    return (
        <div className='job-offering'>
            <ProgressBar value={jobOfferingStep} />
            <SecondStepJobListing />
        </div>
    );
};

export default JobOffering;