import React, { useEffect } from "react";
import { RouterProvider } from "react-router-dom";
import routes from "./pages/route";
import "./App.css";

const App = () => {
  useEffect(() => {
    // Listen to popstate events
    const handlePopState = (event) => {
      console.log("Popstate event:", event);
      window.location.href = `${process.env.REACT_APP_SERP_BASE_URL}/job?page=0&size=10`;
    };

    window.addEventListener("popstate", handlePopState);

    // Cleanup event listener and restore original methods on unmount
    return () => {
      window.removeEventListener("popstate", handlePopState);
    };
  }, []);

  // eslint-disable-next-line no-undef
  if (urlTracking) {
    // eslint-disable-next-line no-undef
    urlTracking = process.env.REACT_APP_BASE_URL;
  }

  return <RouterProvider router={routes} />;
};

export default App;
