import React, { useCallback, useEffect, useMemo, useState } from 'react';
import './JobList.scss'
import DetailsJobs from './DetailsJobs';
import ShortJob from './ShortJob';
import { useLocation } from 'react-router-dom';
import { JobApi } from '../../api/jobApi'

const JobList = () => {
    const location = useLocation();
    const queryParams = new URLSearchParams(location?.search);
    const keyword = queryParams.get('keyword') || 'job';

    const [jobs, setJobs] = useState([]);
    const [selectedJobId, setSelectedJobId] = useState(0)
    const testObj = useMemo(() => ({
        keyword,
        testing: false,
        isFilterDistance: false,
        distance: 100,
        weightKeyword: 0.1,
        weightBid: 0.3,
        weightCR: 0.2,
        weightGEO: 0.4,
        page: 0,
        size: 20
    }), [keyword]);

    const fetchData = useCallback(async () => {
        try {
            const jobData = await JobApi.getJobApi(testObj);
            if (jobData?.data?.content?.length > 0) {
                setJobs(jobData.data.content);
            }
        } catch (err) {
            console.error('Error fetching job data:', err);
        }
    }, [testObj]);

    const handleJobClick = (id) => {
        setSelectedJobId(id);
    };

    useEffect(() => {
        fetchData();
    }, [fetchData]);

    return (
        <div className='job-list-container'>
            {
                jobs?.length > 0 && jobs.map((job, idx) => (
                    selectedJobId === idx ? (
                        <DetailsJobs
                            jobDetail={job}
                            id={idx}
                            key={job.id}
                            handleJobClick={handleJobClick}
                        />
                    ) : (
                        <ShortJob
                            jobDetail={job}
                            id={idx}
                            key={job.id}
                            handleJobClick={handleJobClick}
                        />
                    )
                ))
            }
        </div>
    );
};

export default JobList;